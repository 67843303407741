import React from 'react';
import Img from 'gatsby-image';
import './sponsors.css';

export default props => (
  <ul className="features sponsors">
    {props.items.map(sponsor => (
      <li className="card" key={sponsor.title}>
        {' '}
        <a href={sponsor.link}>
          {sponsor.logo.localFile.childImageSharp ? (
            <Img
              className="card-img-top"
              fluid={sponsor.logo.localFile.childImageSharp.fluid}
              alt={sponsor.title}
            />
          ) : (
            <img
              src={sponsor.logo.localFile.publicURL}
              className="card-img-top"
              alt={sponsor.title}
            />
          )}
          <p className="card-body">{sponsor.title}</p>
        </a>
      </li>
    ))}
  </ul>
);
