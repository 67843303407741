import React from 'react';

export default props => {
  const items = props.items;
  let renderedItems = items ? (
    items.map(item => (
      <section className="timetable" key={item.day}>
        <header className="timetable__header">
          <h3>{item.day}</h3>
        </header>
        <ul className="timetable__list">
          {item.slots.map(slot => (
            <li className="timetable__item" key={slot.title}>
              {slot.title}
            </li>
          ))}
        </ul>
      </section>
    ))
  ) : (
    <p>No Schedule as of yet!</p>
  );
  return <div className="many_timetables">{renderedItems}</div>;
};
