import React from 'react';
import Img from 'gatsby-image';
import './keynote.css';

export default props => (
  <article className="container-fluid keynotes" id="keynotes">
    {props.items.map(keynote => (
      <section className="keynote__item" key={keynote.title}>
        <figure className="keynote__figure">
          <Img
            className="keynote__pic"
            alt={keynote.name}
            fluid={keynote.portrait.localFile.childImageSharp.fluid}
          />
          <figcaption className="keynote__link">
            <a href={keynote.link} target="__blank" rel="noopener noreferrer">
              <h4 className="keynote__name">{keynote.name}</h4>
              <span className="keynote__role">{keynote.role}</span>
            </a>
          </figcaption>
        </figure>
        <div className="keynote__wrap">
          <h2 className="keynote__heading">{keynote.title}</h2>
          <div
            key={`div`}
            id="___gatsby"
            className="keynote__text"
            dangerouslySetInnerHTML={{
              __html: `${keynote.content}`
            }}
          />
        </div>
      </section>
    ))}
  </article>
);
