import React from 'react';
import Img from 'gatsby-image';
import './team.css';

export default props => (
  <div className="team" id="team">
    {props.items.map(member => (
      <section className="team__item" key={member.name}>
        <figure className="team__pic">
          <Img
            className="team__img"
            alt={member.name}
            fluid={member.portrait.localFile.childImageSharp.fluid}
          />
          <figcaption className="team__wrap">
            <h4 className="team__name">{member.name}</h4>
            <span className="team__role">{member.role}</span>
            <span className="team__link">
              <a href={member.link} target="__blank" rel="noopener noreferrer">
                <svg
                  height="36px"
                  width="36px"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 10.013 10.013"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  shapeRendering="geometricPrecision"
                  textRendering="geometricPrecision"
                  imageRendering="optimizeQuality"
                >
                  <path d="M5.006 0a5.007 5.007 0 11.001 10.013A5.007 5.007 0 015.006 0zm1.685 4.571a.294.294 0 10.417.416l.208-.208a1.478 1.478 0 000-2.082 1.476 1.476 0 00-2.082 0l-.932.931a1.477 1.477 0 00.001 2.082.294.294 0 10.416-.416.886.886 0 010-1.25l.931-.931a.886.886 0 011.25 0 .885.885 0 010 1.249l-.209.209zm-3.37.871a.295.295 0 00-.416-.417l-.208.209a1.478 1.478 0 000 2.082 1.476 1.476 0 002.082 0l.931-.931a1.477 1.477 0 000-2.083.294.294 0 10-.416.417.886.886 0 010 1.249l-.932.932a.887.887 0 01-1.249 0 .886.886 0 010-1.25l.208-.208z" />
                </svg>
              </a>
            </span>
          </figcaption>
        </figure>
      </section>
    ))}
  </div>
);
